
  import AeqSelectField from './AeqSelectField'

  export default {
    name: `PackagesComponent`,
    components: { AeqSelectField },
    props: {
      packages: {
        type: Array,
        default: () => []
      },
      value: {
        type: [String, Array],
        default: null
      },
      company: {
        type: String,
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      byName: {
        type: Boolean,
        default: false
      },
      template: {
        type: String,
        default: ``
      }
    },
    data() {
      return {
        loading: false,
        innerValue: this.$helpers.wrapInArray(this.value)
      }
    },
    computed: {
      screeningPackages() {
        return (this.packages.length > 0 ? [...this.packages] : [...this.$store.state.packages.list]).sort((a, b) =>
          b.order > 0 ? a.order - b.order : false
        )
      },
      selectItems() {
        return this.screeningPackages.map((p) => ({ text: p.title, value: this.byName ? p.name : p.id }))
      },
      companyTag() {
        return this.company || this.$store.getters[`companies/getCurrentCompany`]
      }
    },
    mounted() {
      if (this.screeningPackages.length === 0 && this.companyTag !== ``) {
        this.getPackages()
      }
    },
    methods: {
      select({ name, selectable, additional_message: additionalMessage }) {
        if (!selectable) {
          this.$flashMessage.error({
            title: `${this.$t(`messages.error`)}`,
            message: additionalMessage
          })
          return null
        }
        if (this.innerValue.includes(name)) {
          this.innerValue = this.innerValue.filter((p) => p !== name)
        } else {
          if (this.multiple) {
            this.innerValue.push(name)
          } else {
            this.innerValue = [name]
          }
          this.$emit(`last-pick`, this.getPackage(name))
        }
        this.$emit(`input`, this.multiple ? this.innerValue : this.innerValue[0])
      },
      getPackage(name) {
        return this.screeningPackages.find((p) => p.name === name)
      },
      getPackages() {
        this.loading = true
        this.$store.dispatch(`packages/getPackages`)
        this.loading = false
      }
    }
  }
