
  import AeqDateField from '~/components/AeqDateField'
  import AeqFileField from '~/components/AeqFileField'
  import AeqSelectField from '~/components/AeqSelectField'
  import PackagesComponent from '~/components/Packages'
  import Answer from '~/components/Answer'
  import AeqDocumentList from '~/components/AeqDocumentList'

  export default {
    name: `ClientScreeningsPage`,
    components: {
      AeqDocumentList,
      AeqDateField,
      AeqFileField,
      AeqSelectField,
      PackagesComponent,
      Answer
    },
    middleware({ $gates, store, route, redirect }) {
      const perm = store.getters[`menu/getPermissionFromUrl`](route.path)
      if (!perm || (!!perm && !$gates.hasPermission(perm))) {
        redirect(`/`)
      }
    },
    data() {
      return {
        loadedScreening: null,
        openAnswers: [],
        openGroupAnswers: [],
        search_panel: false,
        screeningReasons: [],
        screeningReasonCategories: [],
        screeningClientUsers: [],
        screeningStatus: [],
        screeningResults: [],
        titleTemplate: {
          diploma: `%_pq_diploma_title% - %_pq_school_name%`,
          diploma_2: `(%_pq_date_from|date,MM.YYYY%)`,
          work_experience: `%_pq_experience_type% - %_pq_company_name%`,
          work_experience_2: `(%_pq_date_from|date,MM.YYYY%)`,
          address: `%_pq_address_street_number% - %_pq_address_city%`,
          address_2: `(%_pq_country%)`
        },
        dateTypes: [
          { value: `employee_start`, text: this.$t(`pages.client.employee_start_date`) },
          { value: `start`, text: this.$t(`pages.client.start_date`) },
          { value: `end`, text: this.$t(`pages.client.end_date`) },
          { value: `delete`, text: this.$t(`pages.client.deletion_date`) }
        ],
        simpleSearch: ``,
        simpleSearchRequest: null,
        searchRefresh: 0,
        foundScreenings: null,
        defaultAdvSearch: {
          id: `id`,
          name: ``,
          jobTitle: ``,
          costCenter: ``,
          packages: [],
          screeningReasons: [],
          screeningReasonCategory: [],
          dateType: null,
          dateFrom: ``,
          dateTo: ``,
          clientUsers: [],
          screeningStatus: [],
          screeningResults: []
        },
        advSearch: null,
        uploadTool: false,
        reloadStep: 0,
        loadSteps: false,
        profileDialog: false,
        commentsDialog: false,
        externalsDialog: false,
        documentsDialog: false,
        newDocument: null,
        profileData: null,
        externalsData: null,
        commentsType: null,
        commentsData: null,
        formComment: false,
        newComment: { title: ``, text: `` },
        documentsData: null,
        counterDoc: 0,
        isFormChange: false,
        initialProfileData: null,
        paginator: null,
        initialScreening: this.$route.query.screening,
        inputValidator: {
          department: () => {
            return this.initialProfileData.department.trim() !== this.profileData.department.trim()
          }
        },
        intermediateReportOnload: false,
        downloadAllDocumentsOnload: false
      }
    },
    computed: {
      isConsultOnly() {
        return this.$store.getters[`permissions/userIsConsultonly`]
      },
      currentLanguage() {
        return this.$i18n.locale
      },
      displayTheDownloadAllButton() {
        const permissions = this.$store.getters[`permissions/getCurrentPermissions`] || []
        console.log(`permissions`, permissions)
        return permissions.includes(`document.get_all_zipped`)
      }
    },
    watch: {
      loadedScreening: {
        deep: true,
        handler() {
          this.uploadTool = false
        }
      },
      simpleSearch: {
        deep: true,
        handler(newValue, oldValue) {
          this.delayedRequest()
        }
      }
    },
    unmounted() {
      this.clearTimeout()
    },
    async created() {
      const params = !!this.initialScreening ? { uuid: this.initialScreening } : {}
      await this.getScreenings({ params })
      await this.getFormData()
    },
    methods: {
      scrollOnTop(target) {
        const offset = -5
        this.$vuetify.goTo(target, { offset })
      },
      getInitialScreening() {
        if (!!this.initialScreening && !!this.$refs[`screening_${this.initialScreening}`]) {
          const target = this.$refs[`screening_${this.initialScreening}`][0].$el
          this.scrollOnTop(target)
          target.click()
          this.initialScreening = null
        }
      },
      getLegacyLink() {
        return `https://app.aequivalent.ch/${this.currentLanguage}/client/admin/connection`
      },
      checkPermission() {
        const perm = this.$store.getters[`menu/getPermissionFromUrl`](this.$route.path)
        return !!perm ? this.$gates.hasPermission(perm) : false
      },
      clearRequest() {
        clearTimeout(this.simpleSearchRequest)
      },
      delayedRequest() {
        this.simpleSearchRequest && this.clearRequest()
        const params = { simpleSearch: this.simpleSearch }
        this.simpleSearchRequest = setTimeout(() => this.getScreenings({ params }), 300)
      },
      toggleAdvSearch() {
        this.search_panel = !this.search_panel
        this.foundScreenings = null
        this.advSearch = Object.assign({}, this.defaultAdvSearch)
        this.search_panel ? (this.advSearch.name = this.simpleSearch) : (this.simpleSearch = ``)
      },
      toTitle(screening) {
        if (!screening) {
          return ``
        }
        const ucfirst = this.$helpers.ucfirst
        const {
          title = ``,
          first_name: firstName = ``,
          usual_last_name: usualLastName = ``
        } = screening?.candidate || {}

        return `${ucfirst(title)} ${ucfirst(firstName)} ${usualLastName?.toUpperCase()}`
      },
      async getFormData() {
        const formData = await this.$api.formData.screeningSearch()

        if (formData) {
          const { reasons, reason_categories: reasonCategories, status, results, client_users: clientUsers } = formData
          this.screeningReasons = reasons.map(this.textToUcfirst)
          this.screeningReasonCategories = reasonCategories.map(this.textToUcfirst)
          this.screeningStatus = status.map(this.textToUcfirst)
          this.screeningResults = results.map(this.textToUcfirst)
          this.screeningClientUsers = clientUsers.map(this.textToUcfirst)
        }
      },
      getScreeningsPage(event) {
        const params = {
          pageNumber: event,
          ...this.advSearch,
          simpleSearch: this.simpleSearch
        }
        this.getScreenings({ params })
      },
      getScreenings({ url, params = {} } = {}) {
        this.loadStart()
        this.$api.screenings
          .getAll({ url, params })
          .then((res) => {
            console.log(`res getAll`, res)
            if (!!res) {
              this.setArray({ ...res })
            }
          })
          .catch((err) => {
            console.log(`err getAll`, err)
            if (err?.status === 400) {
              this.openAnswers = null
              this.newDocument = null
              this.loadedScreening = []
            }
          })
          .finally(() => {
            this.loadEnd()
            this.getInitialScreening()
          })
      },
      async getIntermediateReport(url) {
        if (this.intermediateReportOnload) return;
        this.intermediateReportOnload = true
        await this.$api.documents
          .getOne(url)
          .then((res) => {
            this.$helpers.FileSaver.saveAs(new Blob([res._data]), res.headers.get(`content-filename`))
          })
          .catch(() => {
            this.flashMessageError(this.$t(`messages.cant_get_document`))
          })
        this.intermediateReportOnload = false
      },
      getStep(stepId) {
        return this.$api.screeningSteps.get(stepId).then((res) => {
          if (!!res) {
            // Sort Questions
            res.form_elements = this.$helpers.sortElements(res.form_elements, (a, b) => a[1].order - b[1].order)
            console.log(`STEP DETAILS`, res)
            return res
          } else {
            return []
          }
        })
      },
      getArchive(screeningId) {
        this.$api.screenings
          .getDocuments(screeningId)
          .then((res) => {
            const filename = !!res.headers.get(`content-filename`) ? res.headers.get(`content-filename`) : `Archive.zip`
            this.$helpers.FileSaver.saveAs(new Blob([res._data]), filename)
          })
          .catch(() => {
            this.flashMessageError(this.$t(`messages.cant_get_document`))
          })
      },
      getComments(url) {
        return this.$api.comments
          .get(url)
          .then((res) => res)
          .catch(() => {
            this.flashMessageError(this.$t(`messages.cant_get_comments`))
          })
      },
      saveDocument(screeningIdx) {
        if (screeningIdx === null || screeningIdx === undefined) {
          return null
        }

        const documents = this.newDocument[screeningIdx]
        if (!!documents && !!documents.new && documents.new.length > 0) {
          const screeningId = this.loadedScreening[screeningIdx].id
          const comp = this.$refs[`upField-${screeningIdx}`][0] || this.$refs[`upField-${screeningIdx}`]
          this.$api.screenings
            .sendDocuments(screeningId, { new: documents.new })
            .then((res) => {
              if (!!res && !!res._data) {
                this.loadedScreening[screeningIdx].uploaded_documents = res._data
                this.newDocument[screeningIdx] = {}
                comp.refreshComponent(res._data, false, false)
                this.flashMessageSuccess(this.$t(`messages.document_saved`))
                this.counterDoc += 1
              }
            })
            .catch((error) => {
              if (error?.data?.errors?.length > 0) {
                this.flashMessageError(error?.data?.errors[0])
              }
              this.flashMessageError(this.$t(`messages.cant_put_document`))
            })
        }
      },
      deleteDocument(doc) {
        if (!!doc.deleteLink) {
          const deleteLinkWithoutApi = doc.deleteLink.replace(/\/frontoffice/g, ``)
          this.$api.documents
            .delete(deleteLinkWithoutApi)
            .then(() => {
              const index = this.documentsData.findIndex((d) => d.deleteLink === doc.deleteLink)
              this.documentsData.splice(index, 1)
              this.$flashMessage.success({
                title: this.$t(`messages.success`),
                message: this.$t(`messages.document_delete`)
              })
            })
            .catch(() => {
              this.$flashMessage.error({
                title: this.$t(`messages.error`),
                message: this.$t(`messages.cant_delete_document`)
              })
            })
        } else {
          this.$flashMessage.error({
            title: this.$t(`messages.error`),
            message: `${this.$t(`messages.cant_delete_document`)} - ${this.$t(`messages.no_available_link`)}`
          })
        }
      },
      deleteComment(screeningId, commentId) {
        // deleteOnScreening({ screeningId, commentId })
        this.$api.comments.deleteOnScreening({ screeningId, commentId }).then((res) => {
          if (!!res && res.status === 204) {
            this.flashMessageSuccess(this.$t(`messages.comment_delete`))
            this.commentsData = {
              ...this.commentsData,
              comments: this.commentsData.comments.filter((comment) => comment.id !== commentId)
            }
            this.loadedScreening.find((screening) => screening.id === screeningId).comments.number_of_comments -= 1
          }
        })
      },
      getScreening(screeningId, key) {
        this.loadSteps = true
        console.log(`getScreening`, screeningId)
        this.$api.client
          .consultScreening(screeningId, false)
          .then((res) => {
            if (!!res) {
              this.loadedScreening[key] = { ...this.loadedScreening[key], ...res }
            }
          })
          .catch((e) => {
            console.log(`e`, e)
            this.flashMessageError(this.$t(`messages.cant_get_screening`))
          })
          .finally(() => {
            this.loadSteps = false
            this.reloadStep = this.reloadStep++
          })
      },
      candidateAlerts() {},
      otherAlerts() {},
      async saveNewComment(screeningId) {
        if (await this.$refs.commentMessage.validate()) {
          this.$api.comments
            .saveNewCommentOnScreening(screeningId, this.newComment)
            .then((res) => {
              if (!!res) {
                this.commentsData.comments = res
                this.flashMessageSuccess(this.$t(`messages.comment_saved`))
                this.loadedScreening.find((screening) => screening.id === screeningId).comments.number_of_comments += 1
              }
            })
            .catch((err) => {
              console.log(`saveNewComment`, err)
              this.flashMessageError(this.$t(`messages.cant_save_comment`))
            })
          this.formComment = false
          this.newComment = { title: ``, text: `` }
          this.$refs.commentMessage.reset()
        }
      },
      updateInitialData(data) {
        for (const [key, value] of Object.entries(data)) {
          this.initialProfileData[key] = value
          this.isFormChange = false
        }
        return this
      },
      updateProfile(event, screeningId) {
        if (screeningId !== undefined && event !== undefined) {
          const data = {}

          Object.values(this.inputValidator).forEach((validator) => {
            if (!validator()) {
              return
            }
            data[validator.name] = this.profileData[validator.name]
          })

          this.$api.screenings
            .updateProfile(screeningId, data)
            .then((res) => {
              if (!!res) {
                this.updateInitialData(data).flashMessageSuccess(this.$t(`messages.action_confirmed`))
              }
            })
            .catch((error) => {
              if (error.data?.detail) {
                this.flashMessageError(error.data.detail)
              }
              this.flashMessageError(this.$t(`messages.cant_update_screening_profile`))
            })
        }
      },
      updateTransparency(type, screeningId, event) {
        if (!!type && screeningId !== undefined && event !== undefined) {
          const data = {}
          data[type] = event
          this.$api.screenings
            .transparency(screeningId, data)
            .then((res) => {
              if (!!res) {
                this.flashMessageSuccess(this.$t(`messages.action_confirmed`))
              }
            })
            .catch(() => {
              this.flashMessageError(this.$t(`messages.cant_change_transparency`))
            })
        }
      },
      toggleAlerts(idx, screeningId, evt) {
        if (this.loadedScreening[idx].alerts !== evt) {
          this.loadedScreening[idx].alerts = evt
          this.updateAlerts(idx, screeningId)
        }
      },
      updateAlerts(idx, screeningId, alerts = {}) {
        if (idx === undefined || screeningId === undefined) {
          return null
        }

        const alertsData = {
          custom_alerts: this.loadedScreening[idx].custom_alerts,
          selected_alerts: alerts
        }

        this.$api.alertConfiguration
          .updateAlerts(screeningId, alertsData)
          .then((res) => {
            if (!!res) {
              this.flashMessageSuccess(this.$t(`messages.action_confirmed`))
            }
          })
          .catch(() => {
            this.flashMessageError(this.$t(`messages.cant_update_alerts`))
          })
      },
      openProfileDialog(screening) {
        if (!!screening) {
          this.profileData = screening
          this.initialProfileData = { ...this.profileData }
          this.profileDialog = true
          this.isFormChange = false
        }
      },
      openCommentsDialog(data) {
        const commentsUrl = !!data.steps ? `/screenings/${data.id}` : `/screening_answers/${data.id}`
        this.commentsType = !!data.steps ? `SCR` : `STE`
        this.getComments(commentsUrl).then((comments) => {
          this.commentsData = {
            id: data.id,
            comments
          }
          this.commentsDialog = true
        })
      },
      openExternalsDialog(answer) {
        if (answer.externals && answer.externals.amount > 0) {
          this.loadStart()
          this.getExternals(answer.id)
            .then((data) => {
              this.externalsData = data
              this.externalsDialog = true
            })
            .finally(() => {
              this.loadEnd()
            })
        }
      },
      openDocumentsDialog(documents) {
        if (!!documents && documents.length > 0) {
          this.documentsData = documents
          this.counterDoc += 1
          this.documentsDialog = true
        }
      },
      getAbsIndex(screeningIndex, stepIndex, answerIndex) {
        let absIndex = 0
        for (const sIdx in this.loadedScreening[screeningIndex].steps) {
          if (sIdx <= stepIndex) {
            for (const aIdx in this.loadedScreening[screeningIndex].steps[sIdx].answers) {
              if (sIdx < stepIndex || aIdx < answerIndex) {
                absIndex++
                if (this.loadedScreening[screeningIndex].steps[sIdx].groupable) {
                  break
                }
              }
            }
          }
        }
        return absIndex
      },
      isMasterOrResponsable(screening) {
        return (
          this.$store.getters[`permissions/userIsMaster`] ||
          this.$store.getters[`permissions/userIsResponsible`](screening)
        )
      },
      closeAnswer(screeningIndex, stepIndex, answerIndex) {
        const openAnswer = this.openAnswers[screeningIndex]
        if (openAnswer === undefined || openAnswer === null) {
          return null
        }

        const absIndex = this.getAbsIndex(screeningIndex, stepIndex, answerIndex)
        const idx = openAnswer.indexOf(absIndex)

        if (idx > -1) {
          openAnswer.splice(idx, 1)
        }
      },
      toggleAnswer(screeningIndex, stepIndex, answerIndex) {
        const openAnswer = this.openAnswers[screeningIndex]
        if (openAnswer === undefined || openAnswer === null) {
          return null
        }

        const absIndex = this.getAbsIndex(screeningIndex, stepIndex, answerIndex)
        const idx = openAnswer.indexOf(absIndex)
        if (idx > -1) {
          openAnswer.splice(idx, 1)
        } else {
          const currentScreening = this.loadedScreening[screeningIndex]
          const currentStep = currentScreening?.steps[stepIndex]
          if (!!currentScreening && !!currentStep) {
            // Step has not yet been downloaded
            if (!currentStep.form_elements) {
              this.getStep(currentStep.id).then((step) => {
                this.loadedScreening[screeningIndex].steps[stepIndex] = step
                openAnswer.push(absIndex)
              })
            } else {
              openAnswer.push(absIndex)
            }
            this.openGroupAnswers[stepIndex] = []
          }
        }
      },
      closeGroupAnswer(stepIndex, answerIndex) {
        const openGroupAnswer = this.openGroupAnswers[stepIndex]
        if (openGroupAnswer === undefined || openGroupAnswer === null) {
          return null
        }

        const idx = openGroupAnswer.indexOf(answerIndex)
        if (idx > -1) {
          openGroupAnswer.splice(idx, 1)
        }
      },
      toggleGroupAnswer(stepIndex, answerIndex) {
        const openGroupAnswer = this.openGroupAnswers[stepIndex]
        if (openGroupAnswer === undefined || openGroupAnswer === null) {
          return null
        }
        const idx = openGroupAnswer.indexOf(answerIndex)
        idx > -1 ? openGroupAnswer.splice(idx, 1) : openGroupAnswer.push(answerIndex)
      },
      toggleProgTranparency(event, id) {
        this.updateTransparency(`progress`, id, event)
      },
      toggleResTranparency(event, id) {
        this.updateTransparency(`result`, id, event)
      },
      clearAdvSearch() {
        this.advSearch = Object.assign({}, this.defaultAdvSearch)
        this.searchRefresh += 1
        this.search()
      },
      loadStart() {
        this.$nextTick(() => {
          this.$nuxt.$loading.start()
        })
      },
      loadEnd() {
        this.$nextTick(function () {
          this.$nuxt.$loading.finish()
        })
      },
      async search() {
        if (!this.loadedScreening || !this.advSearch) {
          return
        }
        await this.getScreenings({ params: this.advSearch })
      },
      async downloadAll() {
        if (this.downloadAllDocumentsOnload) return;
        if (!this.loadedScreening || !this.advSearch) {
          return
        }
        this.downloadAllDocumentsOnload = true
        await this.$api.documents
          .getAll({ url: `/screenings/documents/`, params: this.advSearch })
          .then((res) => {
            const filename = !!res.headers.get(`content-filename`) ? res.headers.get(`content-filename`) : `Archive.zip`
            this.$helpers.FileSaver.saveAs(new Blob([res._data]), filename)
          })
          .catch(() => {
            this.flashMessageError(this.$t(`messages.cant_get_document`))
          })
        this.downloadAllDocumentsOnload = false
      },
      setArray({ results, ...paginator }) {
        console.log(`setArray`, { results, paginator })
        this.openAnswers = results.map(() => [])
        this.newDocument = results.map(() => ({}))
        this.loadedScreening = results.map((s) => ({ ...s, alerts: false }))
        this.paginator = { ...paginator }
        console.log(`SCREENING LIST`, results)
      },
      flashMessageSuccess(messageSuccess, titleSuccess = this.$t(`messages.success`)) {
        this.$flashMessage.success({
          title: titleSuccess,
          message: messageSuccess
        })
      },
      flashMessageError(messageError, titleError = `<${this.$options.name}> : ${this.$t(`messages.error`)}`) {
        this.$flashMessage.error({
          title: titleError,
          message: messageError
        })
      },
      textToUcfirst(item) {
        return {
          text: this.$helpers.ucfirst(item.text),
          value: item.value
        }
      },
      checkForChanges(event) {
        this.isFormChange = false
        Object.values(this.inputValidator).forEach((validator) => {
          this.isFormChange |= validator()
        })
      }
    }
  }
